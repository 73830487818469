import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../Component/Layout";
import "./Contact.css"; // Custom styles for hover effect

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout
      pageTitle="Contact Us | PostOfficeCalculators"
      metaDescription="Get in touch with PostOfficeCalculators for any inquiries, support, or feedback."
      metaKeywords="contact us, support, inquiries, feedback, PostOfficeCalculators"
      canonicalUrl="https://postofficecalculators.com/contact-us"
      robotsContent="index, follow"
      ogTitle="Contact Us | PostOfficeCalculators"
      ogDescription="Reach out to PostOfficeCalculators for any questions or feedback."
      ogUrl="https://postofficecalculators.com/contact-us"
      ogImage="https://postofficecalculators.com/img/logo.png"
      schemaData={{
        "@context": "https://schema.org",
        "@type": "WebPage",
        "url": "https://postofficecalculators.com/contact-us",
        "name": "Contact Us",
        "description": "Get in touch with PostOfficeCalculators for inquiries, support, or feedback.",
        "publisher": {
          "@type": "Organization",
          "name": "PostOfficeCalculators",
          "logo": {
            "@type": "ImageObject",
            "url": "https://postofficecalculators.com/img/logo.png"
          }
        }
      }}
    >
      {/* Top Banner */}
      <div className="image-aboutus-banner">
        <div className="container">
          <div className="col-md-12 text-center mt-5">
            <h1 className="lg-text text-white">Contact Us</h1>
            <p className="text-white mt-3">
              We're here to help. Reach out to us with any questions or inquiries.
            </p>
          </div>
        </div>
      </div>

      {/* Contact Information Section */}
      <section className="py-5" style={{ marginTop: "50px" }}>
        <div className="container">
          <div className="row align-items-center">
            {/* Left Side Image */}
            <div className="col-md-6 text-center">
              <img
                src="/img/contact.jpg"
                alt="Contact Us"
                className="img-fluid rounded"
              />
            </div>

            {/* Right Side Message */}
            <div className="col-md-6">
              <h2 className="mb-4"><b>Get in Touch</b></h2>
              <p>
                For any inquiries or support, please fill out our Google Form.
                We will get back to you within 24-48 hours.
              </p>
              <p>
                Click the link below to fill out the form:
              </p>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSfbCRtxTcJncx5VVH_xIfwJIaLpLTetB-RWE9PwHajds6I3MQ/viewform"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-outline-success"
              >
                Fill the Google Form
              </a>
            </div>
          </div>

          {/* Contact Info Card */}
          <div className="row mt-5">
            <div className="col-md-12">
              <div className="card shadow-lg">
                <div className="card-body">
                  <h3 className="card-title text-center mb-4">Contact Information</h3>
                  <p className="card-text text-center">
                    <strong>Need immediate help?</strong> We're here for you! Reach out through
                    the details below.
                  </p>
                  <ul className="list-unstyled text-center">
                    <li className="mb-2">
                      <strong>Mobile:</strong> +91 8989567290
                    </li>
                    <li className="mb-2">
                      <strong>Email:</strong>{" "}
                      <Link to="mailto:support@postofficecalculators.com">
                        support@postofficecalculators.com
                      </Link>
                    </li>
                    <li className="mb-2">
                      <strong>Address:</strong> Gram Post Patna Tamoli ,District Panna, Madhya Pradesh, PIN 488333
                    </li>
                  </ul>
                  <p className="text-center mt-3">
                    Your feedback and inquiries are invaluable to us. We aim to respond to all
                    messages within 24-48 hours.
                  </p>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ContactUs;

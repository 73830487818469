import React from 'react'
import Layout from '../Component/Layout'
import { Link } from 'react-router-dom'

const Home = () => {
  return (
    <Layout
    pageTitle="Home | PostOfficeCalculators"
    metaDescription="Welcome to PostOfficeCalculators, your go-to resource for online calculators and tools to simplify various tasks."
    metaKeywords="home, PostOfficeCalculators, online tools, calculators, easy tools"
    canonicalUrl="https://postofficecalculators.com"
    robotsContent="index, follow"
    ogTitle="Home | PostOfficeCalculators"
    ogDescription="Explore PostOfficeCalculators for a wide range of online tools and calculators to help with your daily tasks."
    ogUrl="https://postofficecalculators.com"
    ogImage="https://postofficecalculators.com/img/logo.png"
    schemaData={{
        "@context": "https://schema.org",
        "@type": "WebPage",
        "url": "https://postofficecalculators.com",
        "name": "Home",
        "description": "PostOfficeCalculators offers a range of tools to assist with everyday tasks, making life easier for users.",
        "publisher": {
            "@type": "Organization",
            "name": "PostOfficeCalculators",
            "logo": {
                "@type": "ImageObject",
                "url": "https://postofficecalculators.com/img/logo.png"
            }
        }
    }}
>

    <div className="container-fluid px-4 py-5 my-5 text-center curved">
	<div className="lc-block">
		<div editable="rich">

			<h2 className="display-5 fw-bold">Welcome to  <span style={{color:'#198754'}}> Post Office Tools </span></h2>

		</div>
	</div>
	<div className="lc-block col-lg-6 mx-auto mb-4">
		<div editable="rich">

			<p className="lead ">At PostOfficeCalculators.com, we simplify your financial planning with precise, user-friendly calculators tailored for post office savings and investment schemes. Whether you’re planning for the future, securing a child’s education fund, or growing your retirement savings, our tools help you make informed decisions with ease.</p>

		</div>
	</div>

	<div className="lc-block d-grid gap-2 d-sm-flex justify-content-sm-center"> <Link className="btn btn-success btn-lg px-4 gap-3" to="/all-tools" role="button">Try Calculators</Link>
		<Link className="btn btn-outline-success btn-lg px-4" to="/contact-us" role="button">Contact Us</Link>
	</div>
</div>


  
   <section className="py-5 bg-light" id="scroll-target">
          <div className="container px-5 my-5">
            <div className="row gx-5 align-items-center">
              <div className="col-lg-6">
                <img className="img-fluid rounded mb-5 mb-lg-0" src="./img/post.jpg" alt="main-img" />
              </div>
              <div className="col-lg-6">
                <h2 className="fw-bolder">Post Office Calculator</h2>
                <p className="lead fw-normal text-muted mb-0">
                Accurate and easy-to-use calculators for all Post Office savings schemes. Simplify your financial planning with reliable, instant results at postofficecalculators.com.
                </p>
              </div>
            </div>
          </div>
        </section>
 
 {/* tools section  */}

 <div className='container-fluid' style={{marginTop:'40px'}}>
<p className='text-center' style={{fontSize:'30px',marginBottom:'30px'}}>Explore Our Calculators</p>
 <div className="container">
 
  <div className="row">
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>
  <div className="card-body">
    <h5 className="card-title"><b>MIS Calculator</b>
</h5>
<p className="card-text">
  MIS Calculator assists in calculating monthly income returns from investments, aiding in better financial management.</p>
</div>
</div>
    </div>
    <div className="col mt-5">
    <div class="card" style={{width:'18rem'}}>
  <div class="card-body">
    <h5 class="card-title"><b>FD Calculator</b></h5>
    <p class="card-text">Calculate the maturity amount of Fixed Deposits accurately, helping users can plan their savings and investments effectively. </p>
  </div>
</div>
    </div>
    <div className="col mt-5">
    <div class="card" style={{width:'18rem'}}>
  <div class="card-body">
    <h5 class="card-title"><b>Senior Citizen Savings Scheme</b></h5>
    <p class="card-text">Easily calculate returns on your post office SCSS investment for secure retirement income.</p>
  </div>
</div>
    </div>
    <div className="col mt-5">
    <div class="card" style={{width:'18rem'}}>
  <div class="card-body">
    <h5 class="card-title"><b>SIP Calculator</b></h5>
    <p class="card-text">Calculate your Post Office SIP returns instantly—accurate, reliable, and easy-to-use for smart investments.</p>
  </div>
</div>
    </div>
  </div>

  {/* second rows  */}

  <div className="row mt-5">
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>
  <div className="card-body">
    <h5 className="card-title"><b>NSC Calculator</b></h5>
    <p className="card-text">NSC Calculator helps calculate returns on National Savings Certificates, aiding secure financial planning.</p>
  </div>
</div>
    </div>
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>
    <div className="card-body">
  <h5 className="card-title"><b>TD Calculator</b></h5>
  <p className="card-text">Calculate fixed returns on Post Office Time Deposit investments, helping users plan for secure financial growth.</p>
</div>

</div>
    </div>
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>
  <div className="card-body">
    <h5 className="card-title"><b>Sukanya Samriddhi Yojana Calculator</b></h5>
    <p className="card-text">Sukanya Samriddhi Yojana Calculator estimates savings growth for a girl's future financial security.</p>
  </div>
</div>
    </div>
    <div className="col mt-5">
    <div className="card" style={{width:'18rem'}}>
  <div className="card-body">
    <h5 className="card-title"><b>RD Calculator</b></h5>
    <p className="card-text">the maturity amount accumulated through regular deposits, aiding in disciplined saving and goal-based financial planning.</p>
  </div>
</div>
 </div>
 <br/>
  </div>
  

</div>
</div>
 
    </Layout>
  )
}

export default Home
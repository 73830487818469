import React from 'react'
import { Link } from 'react-router-dom'
import './Header.css'


const Footer = () => {
  return (
    <>
     <section className="pt-24 pb-8  position-relative overflow-hidden mt-4" style={{backgroundColor:'#f2faf3'}}>
  <div className="container position-relative" style={{marginTop:"30px"}}>
    <div className="row pb-20 mb-6 border-bottom border-secondary-dark">
      <div className="col-12 col-lg-5 mb-16 mb-lg-0">
        <Link className="d-inline-block mb-8" to="#">
          <img src="/img/logo.png" alt="Footer-logo" width={'200px'}  />
        </Link>
        <p className="text-secondary mw-xs mb-24 text-black">With PostOfficeCalculators.com, making smart financial decisions is just a click away. Get started now and take control of your financial future.</p>
        <div className="d-flex align-items-center">
          
        </div>
      </div>
      <div className="col-12 col-lg-7">
        <div className="row">
          <div className="col-6 col-md-4 mb-16 mb-md-0">
            <h6 className="fs-9 mb-6 text-uppercase text-secondary">Important Link</h6>
            <ul className="list-unstyled">
              <li className="mb-4"><Link className="btn btn-link Link p-0 text-black text-decoration-none footer-link" to="/about-us">About Us</Link></li>
              <li className='mb-4'><Link className="btn btn-link p-0 text-black Link text-decoration-none footer-link" to="/contact-us">Contact Us</Link></li>
              <li><Link className="btn btn-link p-0 text-black Link text-decoration-none footer-link" to="/contact-us">Report Bug</Link></li>
              
            
            </ul>
          </div>
          {/* <div className="col-6 col-md-4 mb-16 mb-md-0">
            <h6 className="fs-9 mb-6 text-uppercase text-secondary">Some Tools</h6>
            <ul className="list-unstyled">
            <li className="mb-4"><Link className="btn btn-link Link p-0 text-black text-decoration-none footer-link" to="/sip-calculator">Sip Calculator</Link></li>
              <li className="mb-4"><Link className="btn btn-link Link p-0 text-black text-decoration-none footer-link" to="/fd-calculator">FD Calculator</Link></li>
              <li className='mb-4'><Link className="btn btn-link p-0 text-black  Link text-decoration-none footer-link" to="/ppf-calculator">PPF Calculator</Link></li>
              <li><Link className="btn btn-link p-0 text-black  Link text-decoration-none footer-link" to="/swp-calculator">Swp Calculator</Link></li>
            </ul>
          </div> */}
          <div className="col-6 col-md-4">
            <h6 className="fs-9 mb-6 text-uppercase text-secondary">Pages</h6>
            <ul className="list-unstyled">
            <li className="mb-4"><Link className="btn btn-link  Link p-0 text-black text-decoration-none footer-link" to="/privacy-policy">Privacy Policy</Link></li>
              <li className="mb-4"><Link className="btn btn-link Link p-0 text-black text-decoration-none footer-link" to="/disclaimer">Disclaimer</Link></li>
              <li className="mb-4"><Link className="btn btn-link Link p-0 text-black text-decoration-none footer-link" to="/terms-and-conditions">Terms & Conditions</Link></li>
            
            </ul>
          </div>
          <div className="col-6 col-md-4">
          <h6 className="fs-9 mb-6 text-uppercase text-secondary">Address</h6>
          <ul className="list-unstyled">
          <span style={{color:"black"}}>Gram Patna Tamoli Post Patna  Tamoli District Panna MP 488333</span><br></br>
          <br></br>
          <span style={{color:"black"}}>
            Contact - +91 8989567290
          </span>

          </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="text-center">
      <p className="fs-9 text-secondary-light mb-4  mt-3 text-black">Copyright © {new Date().getFullYear()} <span style={{color:'black'}}>postofficecalculators.com</span> All Rights Reserved</p>
    </div>
  </div>
</section>
    </>
  )
}

export default Footer
import React from 'react'
import Layout from '../Component/Layout'
import { Link } from 'react-router-dom'


const AllTools = () => {

  return (
    <Layout
    pageTitle="All Tools | PostOfficeCalculators"
    metaDescription="Explore all the useful tools offered by PostOfficeCalculators, designed to make your tasks easier and more efficient."
    metaKeywords="tools, PostOfficeCalculators, calculators, online tools, all tools"
    canonicalUrl="https://postofficecalculators.com/all-tools"
    robotsContent="index, follow"
    ogTitle="All Tools | PostOfficeCalculators"
    ogDescription="Explore the complete list of tools available on PostOfficeCalculators, designed to help with various tasks."
    ogUrl="https://postofficecalculators.com/all-tools"
    ogImage="https://postofficecalculators.com/img/logo.png"
    schemaData={{
        "@context": "https://schema.org",
        "@type": "WebPage",
        "url": "https://postofficecalculators.com/all-tools",
        "name": "All Tools",
        "description": "Discover all the tools available on PostOfficeCalculators to assist with your tasks.",
        "publisher": {
            "@type": "Organization",
            "name": "PostOfficeCalculators",
            "logo": {
                "@type": "ImageObject",
                "url": "https://postofficecalculators.com/img/logo.png"
            }
        }
    }}
>


      <div className="container-fluid px-4 py-5 my-5 text-center curved">
        <div className="lc-block">
          <div editable="rich">
            <h2 className="display-5 fw-bold">
              All tools of <span style={{ color: '#198754' }}>Post Office Calculators</span>
            </h2>
          </div>
        </div>
        <div className="lc-block col-lg-6 mx-auto mb-4">
          <div editable="rich">
            <p className="lead">
            we make financial planning effortless with our accurate and easy-to-use calculators designed for post office savings and investment schemes. Whether you're saving for the future, building a fund for your child's education, or preparing for retirement, our tools empower you to make confident and informed decisions.
            </p>
          </div>
        </div>
      </div>

      {/* Tools Section */}
      <div className="container-fluid" style={{ marginTop: '40px' }}>
        <p className="text-center" style={{ fontSize: '30px', marginBottom: '30px' }}>
          Try Our Calculators
        </p>
        <div className="container">
          <div className="row">
            <div className="col mt-5">
              <div className="card" style={{ width: '18rem' }}>
                <div className="card-body">
                  <h5 className="card-title">
                  <b>MIS Calculator</b>
                </h5>
                <p className="card-text">
                  MIS Calculator assists in calculating monthly income returns from investments, aiding in better financial management.
                </p>
                  <div className="d-flex justify-content-center">
                    <Link to="/post-office-mis-calculator" className="btn btn-success">
                      Try Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mt-5">
              <div className="card" style={{ width: '18rem' }}>
                <div className="card-body">
                  <h5 className="card-title">
                    <b>FD Calculator</b>
                  </h5>
                  <p className="card-text">
                    Calculate the maturity amount of Fixed Deposits accurately, helping users plan their savings and investments effectively.
                  </p>
                  <div className="d-flex justify-content-center">
                    <Link to="/post-office-fd-calculator" className="btn btn-success">
                      Try Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mt-5">
              <div className="card" style={{ width: '18rem' }}>
                <div className="card-body">
                  <h5 className="card-title">
                    <b>Senior Citizen Savings Scheme</b>
                  </h5>
                  <p className="card-text">
                  Easily calculate returns on your post office SCSS investment for secure retirement income.
                  </p>
                  <div className="d-flex justify-content-center">
                    <Link to="/post-office-scss-calculator" className="btn btn-success">
                      Try Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mt-5">
              <div className="card" style={{ width: '18rem' }}>
                <div className="card-body">
                  <h5 className="card-title">
                    <b>SIP Caculator</b>
                  </h5>
                  <p className="card-text">
                  Calculate your Post Office SIP returns instantly—accurate, reliable, and easy-to-use for smart investments.
                  </p>
                  <div className="d-flex justify-content-center">
                    <Link to="/post-office-sip-calculator" className="btn btn-success">
                      Try Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Second Row */}
          <div className="row mt-5">
            <div className="col mt-5">
              <div className="card" style={{ width: '18rem' }}>
                <div className="card-body">
                  <h5 className="card-title">
                    <b>NSC Calculator</b>
                  </h5>
                  <p className="card-text">
                    NSC Calculator helps calculate returns on National Savings Certificates, aiding secure financial planning.
                  </p>
                  <div className="d-flex justify-content-center">
                    <Link to="/post-office-nsc-calculator" className="btn btn-success">
                      Try Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mt-5">
              <div className="card" style={{ width: '18rem' }}>
                <div className="card-body">
                  <h5 className="card-title">
                    <b>TD Calculator</b>
                  </h5>
                  <p className="card-text">
                  Calculate fixed returns on Post Office Time Deposit investments, helping users plan for secure financial growth.
                  </p>
                  <div className="d-flex justify-content-center">
                    <Link to="/post-office-td-calculator" className="btn btn-success">
                      Try Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mt-5">
              <div className="card" style={{ width: '18rem' }}>
                <div className="card-body">
                  <h5 className="card-title">
                    <b>Sukanya Samriddhi Yojana Calculator</b>
                  </h5>
                  <p className="card-text">
                    Sukanya Samriddhi Yojana Calculator estimates savings growth for a girl's future financial security.
                  </p>
                  <div className="d-flex justify-content-center">
                    <Link to="/post-office-sukanya-samriddhi-calculator" className="btn btn-success">
                      Try Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col mt-5">
              <div className="card" style={{ width: '18rem' }}>
                <div className="card-body">
                  <h5 className="card-title">
                    <b>RD Calculator</b>
                  </h5>
                  <p className="card-text">
                    Calculate the maturity amount accumulated through regular deposits, aiding in disciplined saving and goal-based financial planning.
                  </p>
                  <div className="d-flex justify-content-center">
                    <Link to="/post-office-rd-calculator" className="btn btn-success">
                      Try Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            

          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AllTools
